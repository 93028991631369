import img11 from '/img/action-news/11.jpg'
import img12 from '/img/action-news/12.jpg'
import img21 from '/img/action-news/21.jpg'
import img22 from '/img/action-news/22.jpg'
import img31 from '/img/action-news/31.jpg'
import img32 from '/img/action-news/32.jpg'
import img41 from '/img/action-news/41.jpg'
import img42 from '/img/action-news/42.jpg'
import img51 from '/img/action-news/51.jpg'
import img52 from '/img/action-news/52.jpg'


const pic11 = new Image();
pic11.src = img11
const pic12 = new Image();
pic12.src = img12
const pic21 = new Image();
pic21.src = img21
const pic22 = new Image();
pic22.src = img22
const pic31 = new Image();
pic31.src = img31
const pic32 = new Image();
pic32.src = img32
const pic41 = new Image();
pic41.src = img41
const pic42 = new Image();
pic42.src = img42
const pic51 = new Image();
pic51.src = img51
const pic52 = new Image();
pic52.src = img52
